import { Layout } from '@cfra-nextgen-frontend/shared';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridThemes, keepNoSidePaddingsOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { noResultText } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import {
    ItemVariant3,
    ItemVariant3Container,
} from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant3';
import { GridViewsPanel, Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { DataItem, ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { GetRequestParamsProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { useSsrDataSource } from '@cfra-nextgen-frontend/shared/src/hooks/useSsrDataSource';
import { SetUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { PreferenceType, UserPreferences } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApiNames, RequestTypes, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box, createTheme, Grid, Stack, SxProps, ThemeProvider } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { BadgeBiggestConcerns } from 'components/BadgeBiggestConcerns';
import { availableReports, getResearchAggregates } from 'features/companyProfile/api/company';
import { getFilterComponent, getMenuItems } from 'features/companyProfile/components/CompanyHeadlines';
import { MenuItemState } from 'features/companyProfile/types/company';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { sortPrimaryFirst } from 'utils/arrays';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { getScreenerInfiniteOptionsProps, getScreenerInfiniteRequestParamsConfig } from './InfiniteOptions/screener';
import { WatchlistCompanyIds } from './Watchlist/ManageWatchlist';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';

const ALLOWED_RESEARCH_IN_COMPANY_REPORTS = [
    ResearchTypeId.LegalReport,
    ResearchTypeId.LegalAlert,
    ResearchTypeId.FrsCompanyReport,
    ResearchTypeId.FrsNotebooks,
    ResearchTypeId.FrsEarningsAlert,
    ResearchTypeId.LegalPipeline,
    ResearchTypeId.LegalPipelineSpecialInterestList,
];
const size = 25;
const scrollThresholdPx = Math.round((48 * size) / 2); // take 50% of fetching size, assume each item height is 48px

const requestCommonParams: SearchByParams = {
    securityType: 'research',
    view: 'company_reports',
    orderBy: 'research_report.publish_timestamp',
    sortDirection: 'desc',
};

const screenerInfiniteRequestParamsConfig = {
    queryKeyFirstElement: 'companyReportsScreenerQuery',
    ...getScreenerInfiniteRequestParamsConfig<'research'>(size, 'research'),
};

export const CompanyReportsContainerStyles: SxProps = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
    padding: '8px 10px 15px 10px',
    height: '100%',
    width: '100%'
};

export const gridLoadingContainerStyles: SxProps = { margin: '48px 0', width: '100%', textAlign: 'center' };

const cfraSecurityTradingIdField =
    'insights.research_report_security__trading.research_report_security.security_trading.cfra_security_trading_id';
const cfraCompanyIdField =
    'insights.research_report_security__company.research_report_security.company.cfra_company_id';

type CompanyReportsProps = {
    selectedView: Views;
    onViewChange: (v: Views) => void;
    userPreferences: UserPreferences;
    setPreferences: SetUserPreferences;
    watchlistCompanyIds: WatchlistCompanyIds;
};

export function CompanyReports(props: CompanyReportsProps) {
    const { userPreferences, setPreferences, watchlistCompanyIds } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [selectedView, setSelectedView] = useState<Views>(props.selectedView);
    const [reports, setReports] = useState<Array<MenuItemState>>([]);
    const [selectedReportIds, setSelectedReportIds] = useState<Array<number>>([]);

    useEffect(() => {
        if (selectedView !== props.selectedView) {
            props.onViewChange(selectedView);
        }
    }, [props, props.selectedView, selectedView]);

    const watchlistFilter = useMemo(() => {
        const filter = [];
        const { cfraCompanyId, cfraSecurityTradingId } = watchlistCompanyIds;

        if (cfraCompanyId && cfraSecurityTradingId) {
            if (cfraSecurityTradingId.length > 0) {
                filter.push({ [cfraSecurityTradingIdField]: { values: cfraSecurityTradingId } });
            }

            if (cfraCompanyId.length > 0) {
                filter.push({ [cfraCompanyIdField]: { values: cfraCompanyId } });
            }

            if (cfraSecurityTradingId.length > 0 || cfraCompanyId.length > 0) {
                return { $or: filter };
            }
            return {};
        }
    }, [watchlistCompanyIds]);

    const companyReportsQryProps: SearchByParams = useMemo(
        () => ({
            ...requestCommonParams,
            requestBody: getCompanyReportsReqBody(selectedReportIds, watchlistFilter),
            config: { enabled: !!selectedReportIds?.length },
        }),
        [watchlistFilter, selectedReportIds],
    );

    const companyReportsQry = sendSingleRequest?.(
        {
            size: size,
            ...companyReportsQryProps,
        },
        {
            path: 'research/screener',
            ...screenerInfiniteRequestParamsConfig,
        },
    ) as UseQueryResult<ScreenerResearchData>;

    const researchReportTypesQuery = sendSingleRequest?.(
        {
            path: 'research',
            securityType: 'research',
            includeData: true,
            includeMetadata: false,
            filters: 'insights.research_report.research_type_name',
            requestBody: getCompanyReportsReqBody(ALLOWED_RESEARCH_IN_COMPANY_REPORTS, watchlistFilter),
            config: {},
        },
        {
            apiName: ApiNames.Research,
            requestType: RequestTypes.POST,
            path: 'research/screener-filters',
            queryKeyFirstElement: 'getCompanyReportsResearchTypes',
        },
    );

    useEffect(() => {
        if (researchReportTypesQuery?.isSuccess) {
            const allReports = getResearchAggregates(researchReportTypesQuery?.data);
            const _research_type_ids = userPreferences?.preferences?.homepage?.company_reports_research_type_ids || [];
            const _selectedReportTypes = availableReports
                .filter((report) => _research_type_ids.includes(report.key))
                .map((report) => report.value);

            setReports(
                allReports.map((r) => ({
                    isChecked: _selectedReportTypes.includes(r.value),
                    key: r.key,
                    name: r.value,
                    count: r.count,
                })),
            );

            if (_research_type_ids.length > 0) {
                setSelectedReportIds(_research_type_ids);
            } else {
                setSelectedReportIds(ALLOWED_RESEARCH_IN_COMPANY_REPORTS);
            }
        }
    }, [
        researchReportTypesQuery?.data,
        researchReportTypesQuery?.isSuccess,
        userPreferences?.preferences?.homepage?.company_reports_research_type_ids,
    ]);

    const handleOnChange = useCallback(
        (_e: any, _report: { name: string }) => {
            const newReportState = reports.map((report) => {
                if (report.name === _report.name) {
                    report.isChecked = !report.isChecked;
                }
                return report;
            });
            setReports(newReportState);

            let reportIds: number[] = [];
            const selectedReportNames = newReportState
                .filter((report) => report.isChecked)
                .map((report) => report.name);

            if (selectedReportNames.length) {
                reportIds = availableReports
                    .filter((report) => selectedReportNames.includes(report.value))
                    .map((report) => report.key);
            }

            if (reportIds.length) {
                setSelectedReportIds(reportIds);
                setPreferences(PreferenceType.HomepageCompanyReportsResearchTypeIds, reportIds);
            } else {
                setSelectedReportIds(ALLOWED_RESEARCH_IN_COMPANY_REPORTS);
                setPreferences(PreferenceType.HomepageCompanyReportsResearchTypeIds, []);
            }
        },
        [reports, setPreferences],
    );

    const searchResultsHeader = useMemo(() => {
        return (
            <CardHeaderVariant1
                title='Company Reports'
                containerStyles={{ paddingX: '10px' }}
                slot1={
                    getFilterComponent(
                        getMenuItems(reports, handleOnChange),
                        reports.filter((report) => report.isChecked).length,
                    ) || undefined
                }
                slot3={<GridViewsPanel onClickCallback={setSelectedView} defaultViewSelected={selectedView} />}
            />
        );
    }, [handleOnChange, reports, selectedView]);

    return (
        <ThemeProvider theme={createTheme()}>
            <Stack sx={CompanyReportsContainerStyles}>
                <Grid container>{searchResultsHeader}</Grid>

                {!companyReportsQry?.isFetched && (
                    <Box sx={gridLoadingContainerStyles}>
                        <Layout.Skeleton height='10px' />
                    </Box>
                )}

                {companyReportsQry?.isFetched &&
                    ((companyReportsQry?.data?.results?.research || []).length === 0 ? (
                        <Box sx={gridLoadingContainerStyles}>
                            <ResearchDescriptionText>{noResultText}</ResearchDescriptionText>
                        </Box>
                    ) : (
                        <>
                            {selectedView === Views.GridView && (
                                <CompanyReportsGridView
                                    selectedReportIds={selectedReportIds}
                                    watchlistFilter={watchlistFilter}
                                />
                            )}
                            {selectedView === Views.TableView && (
                                <CompanyReportsTableView
                                    companyReportsQryProps={companyReportsQryProps}
                                    companyReportsQry={companyReportsQry}
                                />
                            )}
                        </>
                    ))}
            </Stack>
        </ThemeProvider>
    );
}

type CompanyReportsView = {
    selectedReportIds: number[];
    watchlistFilter: any;
};

function CompanyReportsTableView(props: { companyReportsQry: any; companyReportsQryProps: any }) {
    const { companyReportsQry, companyReportsQryProps } = props;
    const gridRef = useRef<AgGridReact>(null);

    const { getRows } = useSsrDataSource({
        pageSize: size,
        resultsKey: 'research',
        reqParams: companyReportsQryProps,
        metadataFields: companyReportsQry?.data?._metadata.fields || [],
        reqConfig: { path: 'research/screener', ...screenerInfiniteRequestParamsConfig },
    });

    const { minWidths, customFlexibleColumns, columnDefs }: ReturnType<typeof extractFromScreenerData> = useMemo(() => {
        if (!companyReportsQry.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        return extractFromScreenerData({
            screenerData: companyReportsQry.data,
            cardName: 'Company Research',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoSidePaddings: true,
        });
    }, [companyReportsQry?.data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const TableView = useMemo(() => {
        return (
            <AgGirdCard
                ref={gridRef}
                useSSRMode={true}
                getRowID={getRowID}
                SSRrowsToFetch={size}
                embedFullWidthRows={true}
                columnDefs={columnDefs}
                gridTheme={AgGridThemes.CpGridTheme}
                unlimitedCalculatedHeight
                SSRDataSource={{
                    getRows: (params) => getRows(params, companyReportsQryProps),
                }}
                maxNumberOfRowsToDisplay={10}
                showDefaultExportButton={false}
                suppressRowClickSelection={true}
                customFlexibleColumns={customFlexibleColumns}
                containerStyles={{ marginTop: 0, paddingLeft: '10px' }}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                useDragScroll
                onColumnMovedGetter={keepNoSidePaddingsOnMove}
                autoSizePadding={defaultAutosizePadding}
                tooltipShowDelay={defaultTooltipShowDelay}
            />
        );
    }, [columnDefs, customFlexibleColumns, getResizableMinWidthForColumn, getRows, companyReportsQryProps]);

    return TableView;
}

function CompanyReportsGridView(props: CompanyReportsView) {
    const { selectedReportIds, watchlistFilter = {} } = props;
    const navigate = useNavigate();
    const outerContainerRef = useRef<HTMLDivElement | null>(null);

    const onPillClickHandler = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    const GridView = useMemo(() => {
        return (
            <InfiniteOptions<ScreenerResearchData, DataItem>
                key='gridView'
                OptionsContainer={ItemVariant3Container}
                loadingContainerStyles={{ paddingBottom: '20px' }}
                noResultsFoundBoxSxProps={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '48px',
                }}
                optionsContainerProps={{
                    containerStyles: {
                        paddingLeft: '5px',
                        maxHeight: '510px',
                        overflowY: 'scroll',
                        justifyContent: 'flex-start',
                        ...scrollbarThemeV3,
                    },
                    gap: 1.7
                }}
                infiniteRequestParams={[
                    {
                        path: 'research/screener',
                        size: size,
                        ...requestCommonParams,
                        requestBody: getCompanyReportsReqBody(selectedReportIds, watchlistFilter),
                        config: {
                            enabled: !!selectedReportIds?.length,
                        },
                    },
                    screenerInfiniteRequestParamsConfig,
                ]}
                RowComponent={({ rowData }) => {
                    const orderedAuthors = sortPrimaryFirst(rowData?.research_report_author);
                    const orderedCompanies = sortPrimaryFirst(rowData?.research_report_security__trading);

                    return (
                        <ItemVariant3<ScreenerResearchData>
                            containerStyles={{
                                borderRadius: '10px',
                                padding: '18.75px 24px 24px',
                            }}
                            rootItemProps={{
                                xs: 5.8
                            }}
                            bottomPillItemsThreshold={3}
                            maxMiddleSubTextLength={125}
                            useLinkGetterParams={researchLinkGetterParams}
                            topText={rowData?.research_report?.research_type_name}
                            topSubText={moment(rowData?.research_report.publish_timestamp).format('MMMM D, YYYY')}
                            middleTitle={rowData?.research_report?.title}
                            middleSubText={rowData?.research_report?.teaser || ''}
                            bottomTextItems={orderedAuthors.map((author: any) => author?.platinum?.analyst?.pen_name)}
                            bottomPillItems={orderedCompanies?.map((company: any) => ({
                                content: company?.research_report_security?.security_trading?.ticker_symbol,
                                onClick: () =>
                                    onPillClickHandler(
                                        `/company-profile/security/${company.research_report_security?.security_trading?.cfra_security_trading_id}`,
                                    ),
                            }))}
                            handleOuterSetRequestParamsProps={(
                                setRequestParamsProps: (props: GetRequestParamsProps) => void,
                            ) => {
                                setRequestParamsProps(getRequestParamsPropsVariant1(rowData?.id));
                            }}
                            topTextRightSlot={
                                rowData?.frs_biggest_concerns_list?.is_biggest_concern && <BadgeBiggestConcerns />
                            }
                        />
                    );
                }}
                {...getScreenerInfiniteOptionsProps<'research'>('research')}
                scrollThresholdPx={scrollThresholdPx}
                outerContainerRef={outerContainerRef}
            />
        );
    }, [onPillClickHandler, selectedReportIds, watchlistFilter]);

    return GridView;
}

function getCompanyReportsReqBody(researchReportTypes: number[], watchlistFilter: any = {}) {
    return {
        filters: {
            values: {
                $and: [
                    {
                        'insights.research_report.research_type_id': {
                            values: researchReportTypes,
                        },
                    },
                    ...(Object.keys(watchlistFilter).length > 0 ? [watchlistFilter] : []),
                ],
            },
        },
    };
}

export function ReportsLoadingContainerCard() {
    return (
        <ThemeProvider theme={createTheme()}>
            <Stack sx={CompanyReportsContainerStyles}>
                <Box sx={gridLoadingContainerStyles}>
                    <Layout.Skeleton height='10px' />
                </Box>
            </Stack>
        </ThemeProvider>
    );
}
