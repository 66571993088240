import settingsIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/settings.svg';
import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { ApplicationType } from '@cfra-nextgen-frontend/shared/src/utils';
import { invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { getSavedItemsByIdConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { Box, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { homePageTheme } from 'components/themes/theme';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { horizontalPaddingInSu } from 'utils/lookAndFeel';
import { BiggestConcernsCard } from './BiggestConcernsCard';
import { CompanyReports, ReportsLoadingContainerCard } from './CompanyReports';
import { DailyPacketCard } from './DailyPacketCard';
import { IndustryResearch } from './IndustryResearch';
import { RecentViews } from './RecentViews';
import { TrendingCard } from './TrendingCard';
import { BcLabel } from 'components/BcLabel/BcLabel';
import { ManageWatchlist, WatchlistCompanyIds } from './Watchlist/ManageWatchlist';

const leftColumnWidthInSu = 7.895;

type CompanyReportsRefType = {
    selectedView: Views
}

export function HomePage() {
    const navigate = useNavigate();
    const companyReportsRef = useRef<CompanyReportsRefType>({ selectedView: Views.GridView });
    const companyReportsSmallViewRef = useRef<CompanyReportsRefType>({ selectedView: Views.GridView });
    const { userPreferences, setPreferences } = useUserPreferences({
        applicationType: ApplicationType.InstitutionalPortal,
        getPreferencesConfig: { cacheTime: 500 },
    });

    const [indResearchGics, setIndResearchGics] = useState<string[]>();
    const [watchlistCompanyIds, setWatchlistCompanyIds] = useState<WatchlistCompanyIds>();

    useEffect(() => {
        invalidateQueriesByKey(getSavedItemsByIdConfig.queryKeyFirstElement);
    }, []);

    const handleManageWatchlistClick = useCallback(() => {
        const homepageViewedWatchlistId = userPreferences?.preferences?.homepage?.last_viewed_watchlist_id;
        navigate('/watchlist', { state: { homepageViewedWatchlistId } });
    }, [navigate, userPreferences?.preferences?.homepage?.last_viewed_watchlist_id]);

    const handleOnViewChange = useCallback((ref: React.RefObject<CompanyReportsRefType>, view: Views) => {
        if (ref.current)
            ref.current.selectedView = view;
    }, []);

    return (
        <ThemeProvider theme={homePageTheme}>
            <ResultsContextProvider>
                {userPreferences && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                        <ManageWatchlist
                            setPreferences={setPreferences}
                            userPreferences={userPreferences}
                            setIndResearchGics={setIndResearchGics}
                            watchlistCompanyIds={watchlistCompanyIds}
                            setWatchlistCompanyIds={setWatchlistCompanyIds}
                        />
                        <ETFLinkButton
                            text='Manage Watchlist'
                            onClick={handleManageWatchlistClick}
                            sx={{ '&:hover': { background: 'transparent' } }}
                            endIcon={<Box component='img' src={settingsIcon} width='100%' height='auto' />}
                        />
                    </Box>
                )}

                <Grid container spacing={horizontalPaddingInSu}>
                    <Grid item display={{ xs: 'none', md: 'flex' }} md={leftColumnWidthInSu}>
                        {userPreferences !== undefined && watchlistCompanyIds !== undefined ? (
                            <CompanyReports
                                key={'companyReportsRef'}
                                setPreferences={setPreferences}
                                onViewChange={(view) => handleOnViewChange(companyReportsRef, view)}
                                userPreferences={userPreferences}
                                watchlistCompanyIds={watchlistCompanyIds}
                                selectedView={companyReportsRef.current.selectedView}
                            />
                        ) : (
                            <ReportsLoadingContainerCard />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={12 - leftColumnWidthInSu}>
                        <TrendingCard />
                    </Grid>
                    <Grid item xs={12} sm={6} display={{ md: 'none' }}>
                        <BiggestConcernsCard />
                    </Grid>

                    <Grid item display={{ xs: 'flex', md: 'none' }} width={'100%'}>
                        {userPreferences !== undefined && watchlistCompanyIds !== undefined ? (
                            <CompanyReports
                                key={'companyReportsSmallViewRef'}
                                setPreferences={setPreferences}
                                onViewChange={(view) => handleOnViewChange(companyReportsSmallViewRef, view)}
                                userPreferences={userPreferences}
                                watchlistCompanyIds={watchlistCompanyIds}
                                selectedView={companyReportsSmallViewRef.current.selectedView}
                            />
                        ) : (
                            <ReportsLoadingContainerCard />
                        )}
                    </Grid>

                    <Grid item xs={12} md={leftColumnWidthInSu}>
                        <Stack spacing={horizontalPaddingInSu} width='100%' height='100%'>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <Box
                                    sx={{
                                        marginBottom: '10px',
                                        marginTop: '-13px',
                                    }}>
                                    <BcLabel />
                                </Box>
                            {userPreferences !== undefined && indResearchGics !== undefined ? (
                                <IndustryResearch
                                    gicsCode={indResearchGics}
                                    userPreferences={userPreferences}
                                    setPreferences={setPreferences}
                                />
                            ) : (
                                <ReportsLoadingContainerCard />
                            )}
                            </Box>
                            <Box width='100%'>
                                <Grid container spacing={horizontalPaddingInSu}>
                                    <Grid item xs={6}>
                                        <RecentViews />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DailyPacketCard />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Grid>

                    <Grid item display={{ xs: 'none', md: 'flex' }} md={12 - leftColumnWidthInSu}>
                        <BiggestConcernsCard />
                    </Grid>
                </Grid>
            </ResultsContextProvider>
        </ThemeProvider>
    );
}
